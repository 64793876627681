<template>
  <AppForm
    :id="id"
    ref="countryForm"
    class="p-1"
    :title="
      country
        ? $t('edit_form', { name: country.name }) || `Edit ${country.name}`
        : $t('add_new_country') || 'Add New Country'
    "
    :errors-bag="errorsBag"
    :is-loading="isLoading"
    :success-message="successMessage"
    @hide="$emit('hide')"
    @submit.prevent="handleSubmit"
  >
    <AppInput
      v-model="countryData['name']"
      type="text"
      required
      :label="$t('country') || 'Country'"
    />
    <AppInput
      v-model="countryData['name_ar']"
      type="text"
      required
      :label="$t('country_in_arabic') || 'Country in Arabic'"
    />
  </AppForm>
</template>

<script>
import { AppInput, AppForm } from "@/components/form/index";

export default {
  components: {
    AppInput,
    AppForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    country: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      countryData: {},
      successMessage: null,
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.countries.isLoading.creating ||
        this.$store.state.countries.isLoading.updating
      );
    },
    errorsBag() {
      return this.$store.state.countries.errors;
    },
  },
  watch: {
    country: {
      handler(val) {
        if (!val) {
          this.countryData = {};
          return;
        }
        this.countryData = { ...val };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleSubmit() {
      const actionName = this.country ? "updateData" : "createData";

      this.$store
        .dispatch(`countries/${actionName}`, this.countryData)
        .then(() => {
          // this.$root.$emit("bv::hide::modal", this.id);
          this.$bvModal.hide(this.id);
          this.successMessage =
            this.$t("country_has_been_added_successfully") ||
            "country has been added Successfully";
          this.countryData = {};
        });
    },
    hideForm() {
      // this.$root.$emit("bv::hide::modal", this.id);
      this.$bvModal.hide(this.id);
      this.$refs.countryForm.reset();
      this.successMessage = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
