<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-overlay :show="isLoading" rounded>
        <div class="m-2">
          <!-- Search -->
          <div
            class="
              d-flex
              align-items-center
              justify-content-end
              flex-column flex-md-row
            "
          >
            <b-form-input
              v-model="searchQuery"
              class="search-input mr-md-1 mb-2 mb-md-0 w-auto"
              :placeholder="$t('search_by_name') || 'Search by name'"
              @input="searchHandler"
            />
            <AppButton
              v-if="userCan('countries-add')"
              variant="primary"
              @click="openCountriesForm()"
            >
              <span class="text-nowrap">
                {{ $t("add_new_country") || "Add New Country" }}
              </span>
            </AppButton>
          </div>
        </div>
        <b-table
          class="position-relative"
          :items="countries"
          hover
          responsive
          striped
          primary-key="id"
          :fields="fields"
          show-empty
          :empty-text="
            $t('no_matching_records_found') || 'No matching records found'
          "
        >
          <template #cell(name_en)="data">
            {{ data.item.name }}
          </template>
          <template #cell(name_ar)="data">
            {{ data.item.name_ar }}
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <AppButton
                v-if="userCan('countries-edit')"
                variant="primary"
                class="mb-1"
                @click="openCountriesForm(data.item)"
              >
                {{ $t("edit") || "Edit" }}
              </AppButton>
              <AppButton
                v-if="userCan('countries-delete')"
                variant="danger"
                @click="openDeletionWarning(data.item)"
              >
                {{ $t("delete") || "Delete" }}
              </AppButton>
            </div>
          </template>
        </b-table>
        <div class="mx-2 my-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
                mb-0
              "
            >
              <span v-if="countriesMeta" class="text-muted">
                {{ $t("total_entries", { total: countriesMeta.total }) }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-if="countriesMeta && countriesMeta.total"
                v-model="countriesMeta.current_page"
                :total-rows="countriesMeta.total"
                :per-page="countriesMeta.per_page"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePaginationHandler"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <countriesForm
      v-if="userCan('countries-add') || userCan('countries-edit')"
      :id="'country-form'"
      :country="activeCountry"
      @hide="hidecountriesForm"
    />
    <deleteWarning
      v-if="userCan('countries-delete')"
      :show="isDeletionWarnShown"
      :is-loading="isDeleting"
      :action-label="$t('delete') || 'Delete'"
      :cancel-label="$t('cancel') || 'Cancel'"
      :message="$t('are_you_sure') || 'Are You Sure?'"
      @close="cancelDeletionHandler"
      @delete="deleteCountry"
    />
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BOverlay,
  BFormInput,
  BPagination,
  BRow,
  BCol,
} from "bootstrap-vue";

import countriesForm from "./partials/countriesForm.vue";
import deleteWarning from "@/components/UI/deleteWarning.vue";
import userCan from "@/mixins/UserCan";
import { AppButton } from "@/components/form/index";

export default {
  name: "Countries",
  components: {
    countriesForm,
    BFormInput,
    BCard,
    BButton,
    BTable,
    BOverlay,
    deleteWarning,
    BPagination,
    BRow,
    BCol,
    AppButton,
  },
  data() {
    return {
      activeCountry: null,
      fields: [
        { key: "edit_delete", label: this.$t("edit_delete") || "Edit/Delete" },
        {
          key: "name_en",
          label: this.$t("name_in_english") || "Name In English",
        },
        {
          key: "name_ar",
          label: this.$t("name_in_arabic") || "Name in Arabic",
        },
      ],
      isDeletionWarnShown: false,
      searchQuery: "",
      searchQueryTimer: null,
      appliedFilters: {},
    };
  },
  computed: {
    countries() {
      return this.$store.state.countries.data;
    },
    countriesMeta() {
      return this.$store.state.countries.meta;
    },
    isLoading() {
      return this.$store.state.countries.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.countries.isLoading.deleting;
    },
  },
  created() {
    this.fetchCountries();
  },
  methods: {
    userCan,
    changePaginationHandler(page = 1) {
      this.fetchDataHandler({ page });
    },
    fetchDataHandler(params = null) {
      this.appliedFilters = {
        ...this.appliedFilters,
        page: 1, // reset page to 1 unless page param is passed underneath
        ...params,
      };
      this.$store.dispatch(
        "countries/fetchData",
        JSON.parse(JSON.stringify(this.appliedFilters))
      );
    },
    searchHandler(searchText) {
      // debounce
      clearTimeout(this.searchQueryTimer);
      this.searchQueryTimer = setTimeout(() => {
        const searchQuery = { name: searchText };
        this.$store.dispatch("countries/fetchData", searchQuery);
      }, 300);
    },
    openCountriesForm(item = null) {
      if (item) this.activeCountry = item;
      this.$root.$emit("bv::show::modal", "country-form");
    },
    hidecountriesForm() {
      this.activeCountry = null;
    },
    fetchCountries() {
      this.$store.dispatch("countries/fetchData");
    },
    openDeletionWarning(item) {
      this.isDeletionWarnShown = true;
      this.activeCountry = item;
    },
    cancelDeletionHandler() {
      this.isDeletionWarnShown = false;
      this.activeCountry = null;
    },
    deleteCountry() {
      this.$store
        .dispatch("countries/deleteData", this.activeCountry)
        .then(() => {
          this.activeCountry = null;
          this.isDeletionWarnShown = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
